<template>
  <ul class="steps">
    <li
      v-for="(item, index) in steps"
      :key="index"
      class="steps__item"
      :class="`steps__${item.name}`"
    >
      <span class="steps__num">
        {{ index + 1 }}
      </span>
      <div>
        <p class="steps__desc">
          {{ item.desc }}
        </p>
        <p v-if="item.icons" class="steps__icons">
          <img v-for="(icon, i) in item.icons" :key="i" :src="icon" />
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
import './steps.scss';

export default {
  name: 'Steps',
  data() {
    return {
      steps: [
        {
          name: 'search',
          desc: 'Наберите в строке поиска кадастровый номер или адрес'
        },
        {
          name: 'select',
          desc: 'Выберите нужный вариант в результатах поиска'
        },
        {
          name: 'order',
          desc: 'Закажите отчет онлайн, оплатив удобным вам способом',
          icons: [
            require('@/assets/payment-icons/master-card.svg'),
            require('@/assets/payment-icons/maestro.svg'),
            require('@/assets/payment-icons/mir.svg'),
            require('@/assets/payment-icons/visa.svg')
          ]
        },
        {
          name: 'get',
          desc:
            'Получите полный отчет о доме, участке, квартире или здании'
        }
      ]
    };
  }
};
</script>
